import classes from './donation-form.module.scss';
import { useRouter } from 'next/router';
import { FALLBACK_LOCALE } from 'config';

export const DonationForm = () => {
  const router = useRouter();

  return (
    <div className={classes.root}>
      <iframe
        src={`https://donate.raisenow.io/vgdrk?lng=${
          router.locale ?? FALLBACK_LOCALE
        }`}
        className={classes.iframe}
      ></iframe>
    </div>
  );
};
