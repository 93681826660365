'use client';

import useSWR from 'swr';
import { useEffect, useState } from 'react';
import { swrFetcher } from '../utils/swr-fetcher';

export type UseResourceProps = {
  /**
   * URL of the endpoint
   */
  url: string;

  /**
   * Additional params for the API endpoint
   */
  params?: object;
};

export const useResource = <T>({ url, params = {} }: UseResourceProps) => {
  const [data, setData] = useState<T | null>(null);

  const {
    data: response,
    error,
    isLoading,
  } = useSWR<T>(
    {
      url,
      params: {
        ...params,
      },
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    swrFetcher,
  );

  useEffect(() => {
    if (!isLoading) {
      setData(response ?? null);
    }
  }, [response]);

  return {
    data,
    error,
    isLoading,
  };
};
