import { createContext, useContext } from 'react';
import { DatoArticle } from '../../types/dato';

export type ArticleListContextType = {
  articles: DatoArticle[];
  count: number;
};

const ArticleListContextValue: ArticleListContextType = {
  articles: [],
  count: 0,
};

export const ArticleListContext = createContext(ArticleListContextValue);

export const useArticleListContext = () => useContext(ArticleListContext);
