'use client';

import { useDebounceFn } from '@reactuses/core';
import { useCallback, useState } from 'react';
import { swrFetcher } from '../utils/swr-fetcher';
import { DatoArticle } from 'types/dato';

export interface UsePaginatedArticlesProps {
  /**
   * URL of the endpoint
   */
  url: string;

  /**
   * Size of the batch
   */
  limit?: number;

  /**
   * Size of the offset for the pagination
   */
  offset?: number;

  params?: object;
  initialLoad?: boolean;
  initialArticles?: DatoArticle[];
  initialCount?: number;
}

export const usePaginatedArticles = ({
  url,
  limit = 5,
  offset: initialOffset,
  initialArticles,
  initialCount,
  initialLoad = true,
  params,
}: UsePaginatedArticlesProps) => {
  const [allowFetchData, setAllowFetchData] = useState(initialLoad);
  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(initialOffset ?? 0);
  const [error, setError] = useState<Error | null>(null);
  const [articles, setArticles] = useState(initialArticles ?? []);
  const [count, setCount] = useState(initialCount ?? 0);

  const fetch = useDebounceFn((p: object = {}, isFirst = true) => {
    if (isFirst) {
      setOffset(0);
    }
    setIsLoading(true);
    swrFetcher<{ articles: DatoArticle[]; count: number }>({
      url: allowFetchData ? url : null,
      params: {
        limit,
        offset: isFirst ? 0 : offset,
        ...params,
        ...p,
      },
    })
      .then((response) => {
        setError(null);
        setCount(response.count ?? null);
        setArticles(
          isFirst ? response.articles : [...articles, ...response.articles],
        );
      })
      .catch((e) => {
        setError(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
    setAllowFetchData(true);
  }, 400);

  const firstFetch = (p: object = {}) => {
    fetch.run(p, true);
  };

  const next = useCallback(async () => {
    setAllowFetchData(true);
    fetch.run({ offset: offset + limit, limit }, false);
    setOffset(offset + limit);
  }, [offset, limit, fetch.run]);

  const reset = useCallback(() => {
    setAllowFetchData(true);
    setOffset(initialOffset ?? 0);
  }, []);

  return {
    limit,
    offset,
    fetch: firstFetch,
    articles,
    count,
    next,
    error,
    isLoading,
    reset,
  };
};
