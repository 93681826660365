import {
  ArrowRightIcon,
  Button,
  Chip,
  FilterBar,
  MenuListItem,
  Typo,
} from '@dreipol/pusch-components';
import { useMediaQuery } from '@dreipol/t3-react-media';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/dist/client/router';
import { useSearchParams as useRouterSearchParams } from 'next/navigation';
import { useCallback, useEffect, useMemo } from 'react';
import { Suggestion } from 'src/services/get-suggestions';
import { DatoFocusArticlePlugin } from '../../../types/dato-plugins';
import { useArticleListContext } from '../../context/article-list-context';
import { useIdList } from '../../hooks/use-id-list';
import { usePaginatedArticles } from '../../hooks/use-paginated-articles';
import { useResource } from '../../hooks/use-resource';
import { FilterGroup, FilterGroupItem } from '../../services/get-filters';
import { ArticleList } from '../article-list/article-list';
import { DynamicFilterRenderer } from '../dynamic-filter-renderer/dynamic-filter-renderer';
import classes from './article-filter-list.module.scss';
import { useArticleFilterContext } from 'src/context/article-filter-context';

export type ArticleFilterListProps = {
  teaser?: DatoFocusArticlePlugin[];
  className?: string;
};

export const ArticleFilterList = ({
  teaser,
  className,
}: ArticleFilterListProps) => {
  const { t } = useTranslation();
  const routerParams = useRouterSearchParams();
  const router = useRouter();
  const initialArticles = useArticleListContext();
  const isSm = useMediaQuery('sm');
  const isMd = useMediaQuery('md');
  const isLg = useMediaQuery('lg');
  const isLargerLg = useMediaQuery(['xl', 'xxl']);
  const filtersApi = useResource<FilterGroup[]>({
    url: '/api/filters',
    params: { locale: router.locale },
  });
  const { activeFilter, searchParam, setSearchParams } =
    useArticleFilterContext()!;
  const articlesApi = usePaginatedArticles({
    initialLoad: false,
    url: '/api/articles',
    initialArticles: initialArticles.articles,
    initialCount: initialArticles.count,
    offset: 0,
    limit: parseInt(routerParams.get('limit') ?? '5'),
    params: {
      term: searchParam,
      filterIds: activeFilter,
      locale: router.locale,
    },
  });

  const suggestionsApi = useResource<Suggestion[]>({
    url: '/api/suggestions',
    params: { locale: router.locale },
  });

  const filterList = useIdList<FilterGroupItem, string>(
    (filtersApi.data ?? []).reduce((items, item) => {
      return items.concat(item.items);
    }, [] as FilterGroupItem[]),
    [filtersApi.data],
  );

  const filterSize = useMemo(() => {
    if (isMd) {
      return 'medium';
    }
    if (isLg) {
      return 'medium-large';
    }
    if (isLargerLg) {
      return 'large';
    }
    return 'auto';
  }, [isMd, isLg, isLargerLg]);

  const onSearchTermChange = useCallback(
    (q: string) => {
      setSearchParams(q, activeFilter ?? []);
    },
    [articlesApi.offset, articlesApi.limit, activeFilter],
  );

  const onFilterChange = useCallback(
    (items: FilterGroupItem[]) => {
      const ids = items.map((i) => i.id);
      setSearchParams(searchParam ?? '', ids);
    },
    [articlesApi.offset, articlesApi.limit, searchParam],
  );

  const onFilterClear = useCallback(() => {
    setSearchParams(searchParam ?? '', []);
    articlesApi.reset();
  }, [articlesApi, searchParam]);

  const onSingleFilterClear = useCallback(
    (id: string) => {
      const remainingFilters = activeFilter?.filter((el) => el !== id);
      setSearchParams(searchParam ?? '', remainingFilters ?? []);
    },
    [articlesApi, searchParam],
  );

  const hasMoreArticles = useMemo(() => {
    if (!articlesApi.count) {
      return true;
    }
    return (articlesApi.count ?? 0) > (articlesApi.articles.length ?? 0);
  }, [articlesApi.count, articlesApi.articles]);

  //initially load filters and query if available
  useEffect(() => {
    if (router.query['practiceMap']) return;
    const params = router.query;
    const q = params.q?.toString() ?? '';
    //set filters
    const filterIds = params.filterIds
      ? typeof params.filterIds === 'string'
        ? [params.filterIds]
        : params.filterIds
      : [];
    setSearchParams(q, filterIds);
  }, []);

  //request articles when query params change
  useEffect(() => {
    articlesApi.fetch({
      filterIds: activeFilter,
      term: searchParam,
    });
  }, [router.query]);

  return (
    <div className={clsx(classes.root, className)}>
      <div id="search-bar" className={classes.scrollToContainer}></div>
      <div>
        <FilterBar
          className={classes.filter}
          query={searchParam ?? ''}
          filterSize={filterSize}
          onChange={onSearchTermChange}
          isLoading={articlesApi.isLoading}
          placeholder={t('filter.placeholder')!}
          showFooter={!!(activeFilter && activeFilter.length > 0)}
          suggestions={
            <div className={classes.suggestionContainer}>
              <MenuListItem header>
                {t('search.suggestions-title')}
              </MenuListItem>
              {suggestionsApi.data?.map((suggestion) => {
                return (
                  <MenuListItem
                    className={classes.hover}
                    key={suggestion.id}
                    adornmentBehaviour="auto"
                    onMouseDown={() => onSearchTermChange(suggestion.entry)}
                    endAdornment={<ArrowRightIcon />}
                  >
                    {suggestion.entry}
                  </MenuListItem>
                );
              })}
            </div>
          }
          filters={
            <DynamicFilterRenderer
              filterType="page"
              popUpPosition="right"
              activeFilters={activeFilter ?? []}
              filters={filtersApi.data ?? []}
              onChange={onFilterChange}
              onClear={onFilterClear}
            />
          }
        >
          <div className={classes.filterBar}>
            {activeFilter &&
              activeFilter.map((id) => {
                const item = filterList.get(id);
                if (!item) {
                  return null;
                }
                return (
                  <Chip
                    color={item.color || 'default'}
                    key={item.id}
                    onClick={() => {
                      onSingleFilterClear(item.id);
                    }}
                  >
                    {item.term}
                  </Chip>
                );
              })}

            {!isSm && (
              <Button
                inline
                variant={'link'}
                typoVariant={'textlink'}
                onClick={onFilterClear}
              >
                {t('filter.reset_all')}
              </Button>
            )}
          </div>
        </FilterBar>
      </div>

      {searchParam && (
        <Typo className={classes.searchIndicator} variant="h5">
          {articlesApi.articles.length !== 0
            ? t('search.results-indicator', { query: searchParam })
            : t('search.no-results-idicator', { query: searchParam })}
        </Typo>
      )}
      <ArticleList articles={articlesApi.articles} teaser={teaser} />

      {hasMoreArticles && (
        <div className={classes.loadArticlesButton}>
          <Button
            variant="outlined"
            onClick={articlesApi.next}
            disabled={articlesApi.isLoading}
          >
            {t('load_more_articles')}
          </Button>
        </div>
      )}
    </div>
  );
};
